.ranking {
    width: 100%;
    padding: 40px;
}

.ranking__content {
    width: 80% !important;
    margin: 0 auto;
}

.ranking__header {
    background-color: white;
    padding: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.294);
}

.ranking__header p {
    font-weight: 900;
    font-size: 1.2em;
}

.collection-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collection-item div {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 768px ) {
    .ranking {
        padding-top: 15px;
        padding-left: 5px ;
        padding-right: 5px ;
    }
    .ranking__content {
        width: 100% !important;
    }
}