@import url("https://fonts.googleapis.com/css2?family=Marck+Script&display=swap");

.certificate {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.certificate-container {
  box-shadow: 0px 0px 8px black;
  border-radius: 20px;
  overflow: hidden;
}

.certificate-app {
  background-image: url("../image/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 595px;
  height: 842px;
  padding: 0 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-evenly;
}

.header h5 {
  text-align: center;
}

.content {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content__header {
  padding: 4rem 0;
}

.content__header h1 {
  font-family: "Marck Script", "cursive";
  font-size: 55px;
  text-align: center;
  letter-spacing: 20px;
}

.content p {
  font-size: 25px;
  margin: 0.5rem;
}

.content .name {
  font-size: 35px !important;
  letter-spacing: 5px;
  font-weight: bold;
}

.footer {
  font-weight: bold;
}

.certificate-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.pdfButton {
  margin-right: 1rem;
  color: white;
  background-image: linear-gradient(to right, #ae00ff, #4c008a);
  border: none !important;
  transition: box-shadow 0.5s ease;
  padding: 20px;
  border: 2px solid purple;
  border-radius: 40px;
  font-weight: 900;
  width: 20%;
}

.pdfButton:hover {
  cursor: pointer;
  box-shadow: 0px 2px 8px rgb(125, 9, 233);
}

@media only screen and (max-width: 768px) {
  /* .certificate-container {
    transform: scale(0.55);
    margin-top: -150px;
    margin-bottom: -160px;
  } */
  .pdfButton {
    width: 90%;
    margin-top: 10px;
  }
}
