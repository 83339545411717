.assignments {
  width: 90%;
  margin: 30px auto;
}

.assignments p {
  font-weight: 900;
}

.assignment__body {
  background-color: white;
  padding: 20px !important;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.3);
}

.assignment__body form {
  margin-top: 10px;
}

.answer {
  width: 100%;
  background-color: rgb(228, 228, 228);
  padding: 15px;
  display: flex;
  border-radius: 15px;
}

.answer p {
  font-weight: 900;
  margin-right: 5px;
}

.answer input[type="text"] {
    border: none;
  outline: none;
  padding: 5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.answer button {
  border: none;
  padding: 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
  background-color: rgb(4, 160, 126);
  color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 0.5s ease;
}

.answer button:hover {
  cursor: pointer;
  background-color: rgb(7, 216, 170);
}

.answer button:active {
  background-color: rgb(7, 216, 170);
}

.check-answer .correct-answer {
  font-size: 20px;
  font-weight: 900;
  color: green;
}

.correct-answer span {
  color: purple;
}

.check-answer .wrong-answer {
  font-size: 20px;
  font-weight: 900;
  color: red;
}


.assignment__content {
  padding: 10px;
}

.assignment__content p {
  text-align: justify;
  font-size: 1.3em;
}

@media only screen and (max-width: 453px) {
  .answer {
    flex-direction: column;
    min-height: 150px;
    justify-content: space-between;
  }
  .answer input[type="text"] {
    border-radius: 0px;
    padding: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .answer button {
    border-radius: 10px;
    text-align: center;
  }
}
