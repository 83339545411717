.rules {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  padding-top: 10px;
}
.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
}

.info h3 {
  display: flex;
  flex-direction: column;
  color: #4b597b;
}

.info h3 .bottom-text {
  background-image: linear-gradient(45deg, #937fe9, #34c9fc);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-weight: bolder;
}

.info h5 {
  color: #4b597b;
  font-size: 20px;
  text-align: justify;
  width: 50%;
  text-indent: 30px;
}

@media only screen and (max-width: 1300px) {
  .info h5 {
    width: 70%;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .rules {
    padding: 0;
  }
  .info {
    align-items: center;
  }
  .info h3 {
    width: 100%;
    text-align: center;
  }
  .rules .info h5 {
    width: 80%;
    text-shadow: 0px 0px 12px rgb(131, 131, 131);
    font-size: 1.1em;
  }
}
