.participants {
    width: 100%;
    padding-top: 40px;
}

.participants__header {
    background-color: white;
    padding: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.294);
}

.participants__header p {
    font-weight: 900;
    font-size: 1.2em;
}

.collapsible-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collapsible-header div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.collapsible-body {
    background-color: rgb(246, 246, 246);
}

.collection-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collection-item div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.collection-item i {
    padding-right: 5px;
}