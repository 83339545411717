.nav {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.nav .sidenav-trigger {
  display: none;
  padding: 10px;
  background: none !important;
  border: none !important;
}
.nav ul li {
  display: inline;
}

.nav-wrapper {
  margin-top: 10px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-wrapper .brand-logo {
  transition: all 0.5s ease;
}

.nav-wrapper .brand-logo:hover {
  transform: scale(1.2);
}

.nav-wrapper ul li {
  padding: 20px;
}

.nav-wrapper ul li a {
  position: relative;
  background: linear-gradient(
    to right,
    rgb(79, 76, 250),
    rgb(165, 26, 246) 50%,
    rgb(0, 0, 0) 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
}

.nav-wrapper ul li a:hover {
  background-position: 0 100%;
}

.nav-wrapper ul li a:after {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  display: block;
  right: 0;
  background: rgb(240, 2, 240);
  transition: width 0.2s ease;
  -webkit-transition: width 0.2s ease;
}

.nav-wrapper ul li a:hover:after {
  width: 100%;
  left: 0;
  background: rgb(240, 2, 240);
}

.nav-wrapper .active {
  background: rgb(240, 2, 240) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  border-bottom: 3px solid rgb(240, 2, 240) !important;
  padding: 2px;
}

.userDetails {
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .nav .sidenav-trigger {
    display: block;
  }
  .nav-wrapper {
    width: 70%;
  }
}

@media only screen and (min-width: 992px) {
  .nav-wrapper ul li {
    padding: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .nav-wrapper ul li {
    padding: 10px;
  }
}