.finalAnswer {
  background-color: rgba(255, 255, 255);
  border-radius: 15px;
  margin-top: 50px;
  padding: 20px;
  width: 100%;
  transition: all 0.5s ease;
}

.userInput {
  display: flex;
  flex: 1;
  align-items: center;
}

.userInput input[type="text"]{
  margin: 0 !important;
  font-weight: 700;
}

.userInput button {
  border: none;
  padding: 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
  background: none;
  border: 2px solid rgb(4, 160, 126); 
  color: black;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  transition: all 0.5s ease;
}

.userInput button:hover {
  cursor: pointer;
  background-color: rgb(4, 160, 126);
  color: white;
}

.userInput button:active {
  background-color: rgb(4, 160, 126);
  color: white;
}

.finalAnswer h5 {
  text-align: center;
  font-weight: 900;
}

@media only screen and (max-width: 820px) {
  .finalAnswer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .userInput {
    flex-direction: column;
    width: 100%;
    margin-bottom: 5px;
  }
  .userInput button {
    margin-top: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}