main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-right: 40px;
    padding-top: 2rem;
}

.info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
}

main h3 {
    display: flex;
    flex-direction: column;
    color: #4B597B;
}

main h3 .bottom-text {
    background-image: linear-gradient(45deg, #937FE9, #34C9FC);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    font-weight: bolder;
}

main h5 {
    color: #4B597B;
    font-size: 20px;
    text-align: justify;
    width: 50%;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.buttons a button {
    padding: 20px;
    border: 2px solid purple;
    border-radius: 40px;
    font-weight: 900;
}

.registerButton {
    margin-right: 40px;
    background: #fff;
    background-size: 200% 100%;
    background-position:right bottom;
    font-weight: bold;
    color: purple;
    text-transform: uppercase;
    transition:all 1s ease;
}

.registerButton:hover {
    background: linear-gradient(to right, purple 50%, rgb(255, 255, 255) 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    cursor: pointer;
    color: white;
}

.termsButton {
    color: white;
    background-image: linear-gradient(to right,  #ae00ff, #4c008a);
    border: none !important;
    transition: box-shadow 0.5s ease;
}

.termsButton:hover {
    cursor: pointer;
    box-shadow: 0px 2px 8px rgb(125, 9, 233);
}

@media only screen and (max-width: 1300px) {
    .info h5 {
        width: 70%;
    }
}

@media only screen and (max-width: 1024px) {
    main {
        padding: 0;
    }
    .info {
        align-items: center;
    }
    .info h3 {
        width: 100%;
        text-align: center;
    }
    .info h5 {
        width: 80%;
        text-shadow: 0px 0px 12px rgb(131, 131, 131);
        margin: 0;
    }
    .buttons {
        flex-direction: column;
        margin: 0;
    }

    .buttons a {
        width: 90%;
    }

    .buttons a button {
        width: 100%;
    }
    
    .termsButton {
        margin-top: 10px;
    }
  }