.login {
    width: 100%;
    height: auto;
    display: flex;
    padding-top: 70px;
    padding-right: 40px;
}

.login__spacing {
    flex: 1;
}

.login__content {
    width: 50%;
}

.login__contentHeader h4 {
    text-decoration: underline;
    text-decoration-color: #927EE8;
    color: #4B597B;
    margin-bottom: 50px;
    text-align: right;
}

.login__contentBody form {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    border: 1px outset rgb(214, 214, 214);
    position: relative;
}

.login__contentBody form input[type=text]:focus, input[type=email]:focus, input[type=password]:focus {
    border-bottom: 1px solid #ae00ff !important;
    -webkit-box-shadow: 0 1px 0 0  #ae00ff !important;
    box-shadow: 0 1px 0 0  #ae00ff !important;
}

.login__contentBody form .submitBtn {
    min-width: 120px;
    border: 3px solid #927EE8;
    border-radius: 60px;
    background: #fff;
    padding: 15px;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-20%, 60%);
    color: #927ee8;
    transition: all 0.5s ease;
}

.login__contentBody form .submitBtn:hover {
    cursor: pointer;
    color: #fff;
    background: #927ee8;
}

@media only screen and (max-width: 1024px) {
    .login {
        padding-right: 40px;
        padding-left: 40px;
    }
    .login__content {
        width: 100%;
    }
}

@media only screen and (max-width: 458px) {
    .login__contentHeader h4 {
        text-align: center;
    }
}