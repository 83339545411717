@import url(https://fonts.googleapis.com/css2?family=Marck+Script&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  background: rgb(0, 212, 255);
  background: linear-gradient(
    90deg,
    rgba(0, 212, 255, 1) 0%,
    rgba(146, 126, 232, 1) 66%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

#app {
  width: 60%;
  height: 90vh;
  background-image: url(/static/media/bg.c63bffa8.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: contain;
  border-radius: 10px;
  position: relative;
  overflow-y: auto;
  padding-bottom: 1rem;
}

.disabledBtn {
  pointer-events: none !important;
  background-color: #DFDFDF !important;
  box-shadow: none !important;
  color: #9F9F9F !important;
  cursor: default !important;
  border: 1px solid black !important;
}

@media only screen and (max-width: 768px) {
  #app {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }
}
main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-right: 40px;
    padding-top: 2rem;
}

.info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
}

main h3 {
    display: flex;
    flex-direction: column;
    color: #4B597B;
}

main h3 .bottom-text {
    background-image: linear-gradient(45deg, #937FE9, #34C9FC);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    font-weight: bolder;
}

main h5 {
    color: #4B597B;
    font-size: 20px;
    text-align: justify;
    width: 50%;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.buttons a button {
    padding: 20px;
    border: 2px solid purple;
    border-radius: 40px;
    font-weight: 900;
}

.registerButton {
    margin-right: 40px;
    background: #fff;
    background-size: 200% 100%;
    background-position:right bottom;
    font-weight: bold;
    color: purple;
    text-transform: uppercase;
    transition:all 1s ease;
}

.registerButton:hover {
    background: linear-gradient(to right, purple 50%, rgb(255, 255, 255) 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    cursor: pointer;
    color: white;
}

.termsButton {
    color: white;
    background-image: linear-gradient(to right,  #ae00ff, #4c008a);
    border: none !important;
    transition: box-shadow 0.5s ease;
}

.termsButton:hover {
    cursor: pointer;
    box-shadow: 0px 2px 8px rgb(125, 9, 233);
}

@media only screen and (max-width: 1300px) {
    .info h5 {
        width: 70%;
    }
}

@media only screen and (max-width: 1024px) {
    main {
        padding: 0;
    }
    .info {
        align-items: center;
    }
    .info h3 {
        width: 100%;
        text-align: center;
    }
    .info h5 {
        width: 80%;
        text-shadow: 0px 0px 12px rgb(131, 131, 131);
        margin: 0;
    }
    .buttons {
        flex-direction: column;
        margin: 0;
    }

    .buttons a {
        width: 90%;
    }

    .buttons a button {
        width: 100%;
    }
    
    .termsButton {
        margin-top: 10px;
    }
  }
.nav {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.nav .sidenav-trigger {
  display: none;
  padding: 10px;
  background: none !important;
  border: none !important;
}
.nav ul li {
  display: inline;
}

.nav-wrapper {
  margin-top: 10px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-wrapper .brand-logo {
  transition: all 0.5s ease;
}

.nav-wrapper .brand-logo:hover {
  transform: scale(1.2);
}

.nav-wrapper ul li {
  padding: 20px;
}

.nav-wrapper ul li a {
  position: relative;
  background: linear-gradient(
    to right,
    rgb(79, 76, 250),
    rgb(165, 26, 246) 50%,
    rgb(0, 0, 0) 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
}

.nav-wrapper ul li a:hover {
  background-position: 0 100%;
}

.nav-wrapper ul li a:after {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  display: block;
  right: 0;
  background: rgb(240, 2, 240);
  transition: width 0.2s ease;
  -webkit-transition: width 0.2s ease;
}

.nav-wrapper ul li a:hover:after {
  width: 100%;
  left: 0;
  background: rgb(240, 2, 240);
}

.nav-wrapper .active {
  background: rgb(240, 2, 240) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  border-bottom: 3px solid rgb(240, 2, 240) !important;
  padding: 2px;
}

.userDetails {
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .nav .sidenav-trigger {
    display: block;
  }
  .nav-wrapper {
    width: 70%;
  }
}

@media only screen and (min-width: 992px) {
  .nav-wrapper ul li {
    padding: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .nav-wrapper ul li {
    padding: 10px;
  }
}
.register {
    width: 100%;
    height: auto;
    display: flex;
    padding-top: 1rem;
    padding-right: 40px;
}

.register__spacing {
    flex: 1 1;
}

.register__content {
    width: 50%;
}

.register__contentHeader h4 {
    text-decoration: underline;
    -webkit-text-decoration-color: #927EE8;
            text-decoration-color: #927EE8;
    color: #4B597B;
    text-align: right;
}

.register__contentBody form {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    border: 1px outset rgb(214, 214, 214);
    position: relative;
}

.register__contentBody form input[type=text]:focus, input[type=email]:focus, input[type=password]:focus {
    border-bottom: 1px solid #ae00ff !important;
    box-shadow: 0 1px 0 0  #ae00ff !important;
}

.register__contentBody form .submitBtn {
    border: 3px solid #927EE8;
    border-radius: 60px;
    background: #fff;
    padding: 15px;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-20%, 60%);
    color: #927ee8;
    transition: all 0.5s ease;
}

.register__contentBody form .submitBtn:hover {
    cursor: pointer;
    color: #fff;
    background: #927ee8;
}

select {
    display: block;
}

select:hover {
    cursor: pointer;
}

@media only screen and (max-width: 1024px) {
    .register {
        padding-right: 40px;
        padding-left: 40px;
        padding-top: 0;
    }
    .register__content {
        width: 100%;
    }
}

@media only screen and (max-width: 458px) {
    .register {
        padding-top: 0;
    }
    .register__contentHeader h4 {
        text-align: center;
    }
}
.login {
    width: 100%;
    height: auto;
    display: flex;
    padding-top: 70px;
    padding-right: 40px;
}

.login__spacing {
    flex: 1 1;
}

.login__content {
    width: 50%;
}

.login__contentHeader h4 {
    text-decoration: underline;
    -webkit-text-decoration-color: #927EE8;
            text-decoration-color: #927EE8;
    color: #4B597B;
    margin-bottom: 50px;
    text-align: right;
}

.login__contentBody form {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    border: 1px outset rgb(214, 214, 214);
    position: relative;
}

.login__contentBody form input[type=text]:focus, input[type=email]:focus, input[type=password]:focus {
    border-bottom: 1px solid #ae00ff !important;
    box-shadow: 0 1px 0 0  #ae00ff !important;
}

.login__contentBody form .submitBtn {
    min-width: 120px;
    border: 3px solid #927EE8;
    border-radius: 60px;
    background: #fff;
    padding: 15px;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-20%, 60%);
    color: #927ee8;
    transition: all 0.5s ease;
}

.login__contentBody form .submitBtn:hover {
    cursor: pointer;
    color: #fff;
    background: #927ee8;
}

@media only screen and (max-width: 1024px) {
    .login {
        padding-right: 40px;
        padding-left: 40px;
    }
    .login__content {
        width: 100%;
    }
}

@media only screen and (max-width: 458px) {
    .login__contentHeader h4 {
        text-align: center;
    }
}
.assignments {
  width: 90%;
  margin: 30px auto;
}

.assignments p {
  font-weight: 900;
}

.assignment__body {
  background-color: white;
  padding: 20px !important;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.3);
}

.assignment__body form {
  margin-top: 10px;
}

.answer {
  width: 100%;
  background-color: rgb(228, 228, 228);
  padding: 15px;
  display: flex;
  border-radius: 15px;
}

.answer p {
  font-weight: 900;
  margin-right: 5px;
}

.answer input[type="text"] {
    border: none;
  outline: none;
  padding: 5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.answer button {
  border: none;
  padding: 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
  background-color: rgb(4, 160, 126);
  color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 0.5s ease;
}

.answer button:hover {
  cursor: pointer;
  background-color: rgb(7, 216, 170);
}

.answer button:active {
  background-color: rgb(7, 216, 170);
}

.check-answer .correct-answer {
  font-size: 20px;
  font-weight: 900;
  color: green;
}

.correct-answer span {
  color: purple;
}

.check-answer .wrong-answer {
  font-size: 20px;
  font-weight: 900;
  color: red;
}


.assignment__content {
  padding: 10px;
}

.assignment__content p {
  text-align: justify;
  font-size: 1.3em;
}

@media only screen and (max-width: 453px) {
  .answer {
    flex-direction: column;
    min-height: 150px;
    justify-content: space-between;
  }
  .answer input[type="text"] {
    border-radius: 0px;
    padding: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .answer button {
    border-radius: 10px;
    text-align: center;
  }
}

.finalAnswer {
  background-color: rgba(255, 255, 255);
  border-radius: 15px;
  margin-top: 50px;
  padding: 20px;
  width: 100%;
  transition: all 0.5s ease;
}

.userInput {
  display: flex;
  flex: 1 1;
  align-items: center;
}

.userInput input[type="text"]{
  margin: 0 !important;
  font-weight: 700;
}

.userInput button {
  border: none;
  padding: 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
  background: none;
  border: 2px solid rgb(4, 160, 126); 
  color: black;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  transition: all 0.5s ease;
}

.userInput button:hover {
  cursor: pointer;
  background-color: rgb(4, 160, 126);
  color: white;
}

.userInput button:active {
  background-color: rgb(4, 160, 126);
  color: white;
}

.finalAnswer h5 {
  text-align: center;
  font-weight: 900;
}

@media only screen and (max-width: 820px) {
  .finalAnswer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .userInput {
    flex-direction: column;
    width: 100%;
    margin-bottom: 5px;
  }
  .userInput button {
    margin-top: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.rules {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  padding-top: 10px;
}
.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
}

.info h3 {
  display: flex;
  flex-direction: column;
  color: #4b597b;
}

.info h3 .bottom-text {
  background-image: linear-gradient(45deg, #937fe9, #34c9fc);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-weight: bolder;
}

.info h5 {
  color: #4b597b;
  font-size: 20px;
  text-align: justify;
  width: 50%;
  text-indent: 30px;
}

@media only screen and (max-width: 1300px) {
  .info h5 {
    width: 70%;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .rules {
    padding: 0;
  }
  .info {
    align-items: center;
  }
  .info h3 {
    width: 100%;
    text-align: center;
  }
  .rules .info h5 {
    width: 80%;
    text-shadow: 0px 0px 12px rgb(131, 131, 131);
    font-size: 1.1em;
  }
}

.participants {
    width: 100%;
    padding-top: 40px;
}

.participants__header {
    background-color: white;
    padding: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.294);
}

.participants__header p {
    font-weight: 900;
    font-size: 1.2em;
}

.collapsible-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collapsible-header div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.collapsible-body {
    background-color: rgb(246, 246, 246);
}

.collection-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collection-item div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.collection-item i {
    padding-right: 5px;
}
.ranking {
    width: 100%;
    padding: 40px;
}

.ranking__content {
    width: 80% !important;
    margin: 0 auto;
}

.ranking__header {
    background-color: white;
    padding: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.294);
}

.ranking__header p {
    font-weight: 900;
    font-size: 1.2em;
}

.collection-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collection-item div {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 768px ) {
    .ranking {
        padding-top: 15px;
        padding-left: 5px ;
        padding-right: 5px ;
    }
    .ranking__content {
        width: 100% !important;
    }
}
.certificate {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.certificate-container {
  box-shadow: 0px 0px 8px black;
  border-radius: 20px;
  overflow: hidden;
}

.certificate-app {
  background-image: url(/static/media/bg.c63bffa8.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 595px;
  height: 842px;
  padding: 0 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-evenly;
}

.header h5 {
  text-align: center;
}

.content {
  text-align: center;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.content__header {
  padding: 4rem 0;
}

.content__header h1 {
  font-family: "Marck Script", "cursive";
  font-size: 55px;
  text-align: center;
  letter-spacing: 20px;
}

.content p {
  font-size: 25px;
  margin: 0.5rem;
}

.content .name {
  font-size: 35px !important;
  letter-spacing: 5px;
  font-weight: bold;
}

.footer {
  font-weight: bold;
}

.certificate-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.pdfButton {
  margin-right: 1rem;
  color: white;
  background-image: linear-gradient(to right, #ae00ff, #4c008a);
  border: none !important;
  transition: box-shadow 0.5s ease;
  padding: 20px;
  border: 2px solid purple;
  border-radius: 40px;
  font-weight: 900;
  width: 20%;
}

.pdfButton:hover {
  cursor: pointer;
  box-shadow: 0px 2px 8px rgb(125, 9, 233);
}

@media only screen and (max-width: 768px) {
  /* .certificate-container {
    transform: scale(0.55);
    margin-top: -150px;
    margin-bottom: -160px;
  } */
  .pdfButton {
    width: 90%;
    margin-top: 10px;
  }
}

